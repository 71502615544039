import React, {useEffect} from "react"
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import {useNavigate} from 'react-router-dom'
import {Helmet} from "react-helmet";
import CustomCarousel from "../../components/Carousel"
import PhoneNumber from "../../components/phone-number"
import { RoutePaths } from "../../constant";
import ProductInfo from '../../components/productInfo'
import Partners from "../../components/partners";
import Services from "../../components/services"
import ServicesOffered from '../../components/ServicesOffered'

import "./../../common/css/global.css";
import "./home.css"

const Home = ()=> {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const navigateToServices = () => {
        navigate(RoutePaths.services);
      };
    return (
        <>
        <Helmet>
            <title>Ambulance service in Hyderabad – 9318108108</title>
            <meta name="description" content="Reliable ambulance service in Hyderabad, providing 24/7 emergency care with advanced medical support and rapid response. Call now for immediate assistance at 9318108108" />
            <meta name="keywords" content="Hyderabad Ambulance service" />
        </Helmet>
        <PhoneNumber isSticky />
        <div className="sticky-page">
            <h1 style={{display: "none"}}>Fastest Ambulance Services in Hyderabad - Bhavya Ambulance</h1>
            <h1 style={{display: "none"}}>Standby ambulance services in Hyderabad - 9318108108</h1>
            <h1 style={{display: "none"}}>Embalming services in Hyderabad - 9318108108</h1>
            <h1 style={{display: "none"}}>Emergency ambulance services in Hi-tech City - 9318108108</h1>
            <h1 style={{display: "none"}}>Best ambulance services in Hyderabad - 9318108108</h1>
            <CustomCarousel />
            <div className="component-layout">
                <Partners />
                <div className="descriptionSection">
                    <h2 className="section-title">The Best Ambulance Service in Hyderabad | Bhavya Ambulance</h2>
                    <p className="descContent">Bhavya Ambulance Services in Hyderabad Call – 9318108108, we are the largest and best ambulance service in Hyderabad, we provide ambulance services across Hyderabad and Telangana.</p>
                    <p className="descContent">When it comes to emergency medical transportation in Hyderabad? look no further than Bhavya Ambulance Service in Hyderabad. Renowned as the best private ambulance service in Hyderabad, we are dedicated to providing our community with top-notch care and reliable service. including well-equipped transport ventilators, cardiac monitors, and life-saving medications for the patient till he or she is taken to the hospital. We offer ambulance services at extremely low prices. Our fleet of ambulances is equipped with state-of-the-art medical equipment and staffed by highly trained professionals, ensuring that patients receive the highest standard of care during transit.</p>
                    <p className="descContent">If you need an outstation ambulance? We provide outstation ambulance services from Hyderabad to major cities such as Vijayawada and Vizag in Andhra Pradesh, as well as to Bangalore, Chennai, and other locations across India.</p>
                    <p className="descContent">We utilize GPS technology to track and reach the destination as quickly as possible.</p>
                    <p className="descContent">It is critical to be acquainted with the most dependable ambulance service providers. Because you need to know the correct emergency ambulance number in Hyderabad and the surrounding areas, Bhavya Ambulance is available. Whether you're in Madhapur, Kukatpally, Kondapur, Gachibowli, Hitech City, Panjagutta, Ameerpet, Lingampally, Secunderabad, or any other area in Hyderabad, our fastest ambulance service in Hyderabad is available 24/7 to respond swiftly to your emergency needs. From basic life support to critical care transport, our ambulance service in Hyderabad is equipped to handle any situation with expertise and compassion. Please Don't settle for anything less than the best regarding your health and safety. Trust Bhavya Ambulance Service in Hyderabad for prompt, professional, and reliable ambulance service in Hyderabad, whenever you need it most.</p>
                </div>
                <ProductInfo
                    title= 'access to the right emergency'
                    highlightTitle="Immediate"
                    subTitle='Reliable Ambulance Services near you in Hyderabad'
                    image='images/patient-in-ambulance.svg'
                    description="It is crucial to be acquainted with dependable ambulance service providers in your vicinity. Bhavya Ambulance Services in Hyderabad is here to provide you with immediate access to the right emergency ambulance contact number. We aim to in still a sense of security within the city, assuring everyone that when an emergency strikes, our ambulance services will be there to assist. With the finest medical staff in Hyderabad, we take pride in delivering exceptional ambulance services."
                />
                <ProductInfo
                    title= 'highly trained professionals'
                    highlightTitle="Staffed by"
                    subTitle='Our Comprehensive Range of Services and Facilities'
                    image='images/service-crew.png'
                    description="Bhavya Ambulance Services in Hyderabad offers a comprehensive range of options, including Advanced Life Support (ALS), Basic Life Support (BLS), and Patient Transport Ambulances, Embalming services, Air Ambulance, Body Freezer Box Ambulance, Standby Ambulance. Equipped with state-of-the-art medical tools and staffed by highly trained professionals, we are the preferred choice for ambulance services in Hyderabad."
                    flipProduct
                />
                <ProductInfo
                    title= 'medical support during transport'
                    highlightTitle="Intensive"
                    subTitle='Critical Care and ICU Ventilator Ambulance Services in Hyderabad'
                    image='images/patient-service.png'
                    description="In addition to our standard services, Bhavya Ambulance Services offers critical care and ventilator ambulance services in Hyderabad for patients requiring intensive medical support during transport. Count on us for specialized care when every second counts.
                    In Hyderabad, When Every Second Counts, Rely on Bhavya Ambulance Services
                    We understand the importance of timely and efficient ambulance services in Hyderabad, and we are committed to serving the community during its most critical moments. When emergencies arise, stay calm and rely on Bhavya Ambulance Services in Hyderabad for swift and reliable assistance. Your safety and well-being are our top priorities.
                    "
                />
                <Services />
                <ServicesOffered />
            </div>
        </div>
        </>
    )
}

export default Home;