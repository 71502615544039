import React, {useEffect} from "react"
import {Helmet} from "react-helmet";
import "../../common/css/global.css"
import Blogs from '../../components/blogs'
import PhoneNumber from "../../components/phone-number"
import ProductInfo from '../../components/productInfo'
import ServicesOffered from '../../components/ServicesOffered'
import "./aboutUs.css"

const AboutUs = ()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
        <Helmet>
            <title>Emergency Services in Hyderabad | Bhavya Ambulance Services</title>
            <meta name="description" content="Best ambulance services in Hyderabad for your emergency enquiry Bhavya Ambulance Services" />
            <meta name="keywords" content="Bhavya Ambulance services in Hyderabad" />
        </Helmet>
        <h1 style={{display: "none"}}>Emergency Ambulance Services in Hyderabad</h1>
        <PhoneNumber isSticky />
        <div className="page-about-us sticky-page">
            <div className="page-banner">
                <img src="images/about-us.svg" alt="banner"/>
                <div className="page-caption page-caption-whoweare">
                    <p className="hide-mobile">24x7 Ambulance in Hyderabad Call And Book Ambulance</p>
                    <div className="page-divider hide-mobile"></div>
                    <h2 className="mt-15">About Us</h2>
                </div>
            </div>
            <div className="aboutus-section component-layout">
                <ProductInfo
                    title= 'Medical Transportation'
                    highlightTitle="Immediate"
                    subTitle='Fastest and Most Reliable Ambulance Services in Hyderabad'
                    image='images/about-us-caption.svg'
                    description="Bhavya ambulance services are one of the pioneers in emergency medical care and services at all instances. Since the inception, we are providing reliable critical care and emergency services."
                    flipProduct
                    showAboutUsCaption
                />
                <Blogs />
                <ServicesOffered />
            </div>
            
            
        </div>
        </>
        
    )
}

export default AboutUs;