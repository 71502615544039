import React from "react"
import { Link } from "react-router-dom";

import "./corporate.css"
const CorporateCard = ({title, badgeText, description, buttonText, imageUrl}) => {
    return (
        <div className="corp-card">
            <div className="corp-card-badge">
            {badgeText}
            </div>
            <p className="corp-card-title">{title}</p>
            <p className="corp-card-des">{description}</p>
            <div className="corp-card-events">
                <img src={imageUrl}/>
                <Link to="/contact" className="btn secondary">{buttonText}</Link>
            </div>
        </div>
    )
}

export default CorporateCard;