import React from "react";
import "./phone.css"
const PhoneNumber = ({isSticky}) => {
    return (
        <div className={`phone-number ${isSticky ? "phone-sticky" : "phone-not-sticky"}`}>
            <a className="phone-link" href="tel:9318108108"><img  src="images/sticky-phone.png" />
            9318108108</a>
        </div>
    )
}

export default PhoneNumber