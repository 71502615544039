import React, {useState} from "react";
import { Link } from "react-router-dom";
import { RoutePaths } from "../../constant";
import "./header.css"
const Header = ({ currentPage, handleMenuClick, showMenu })=> {
    const [showWho, setShowWho] = useState(false);
    const [showServices, setShowServices] = useState(false);
    const handleMenuClickHeader = (e, showWhoWe, showServ) => {
        console.log(e, showWhoWe, showServ)
        e.stopPropagation();
        showWhoWe ? setShowWho(true) : setShowWho(false);
        showServ ? setShowServices(true) : setShowServices(false);
        !showWhoWe && !showServ && handleMenuClick()
    }
    return (
        <div className="header-container">
            <div className="mobile-menu" onClick={(e)=> handleMenuClickHeader(e)}>
                <div className="ham-menu-bar"/>
                <div className="ham-menu-bar"/>
                <div className="ham-menu-bar"/>
            </div>
            <div className="header-logo">
            <Link to={RoutePaths.home}><img src="images/header-logo.svg" alt="logo"/></Link>
            </div>
            <div className={"header-menus " + (showMenu ? 'show-menu' : '')}>
                <nav className="header-nav">
                    <ul>
                        <li className={(currentPage === RoutePaths.home && !showWho && !showServices) ? 'active-menu' : ''}>
                            <Link to={RoutePaths.home} onClick={(e)=> handleMenuClickHeader(e)}>Home</Link>
                        </li>
                        
                        <li className={((currentPage === RoutePaths.services && !showWho) || showServices) ? 'active-menu' : ''} onClick={(e)=> handleMenuClickHeader(e, false, true)}>
                            <p>Services</p>
                            {showServices && <div className="subMenu serviceSubMenu">
                                <Link className="subMenuLink" to={`${RoutePaths.airAmbulance}`} onClick={(e)=> handleMenuClickHeader(e)}>Air Ambulance</Link>
                                <Link className="subMenuLink" to={`${RoutePaths.embalingServices}`} onClick={(e)=> handleMenuClickHeader(e)}>Embaling Services</Link>
                                <Link className="subMenuLink" to={`${RoutePaths.freezerBox}`} onClick={(e)=> handleMenuClickHeader(e)}>Freezer Box Ambulance</Link>
                                <Link className="subMenuLink" to={`${RoutePaths.icuVentilator}`} onClick={(e)=> handleMenuClickHeader(e)}>ICU Ventilator</Link>
                                <Link className="subMenuLink" to={`${RoutePaths.roadAmbulance}`} onClick={(e)=> handleMenuClickHeader(e)}>Road transport Ambulance</Link>
                                <Link className="subMenuLink" to={`${RoutePaths.standByAmbulance}`} onClick={(e)=> handleMenuClickHeader(e)}>Standby Ambulance</Link>
                            </div>}
                        </li>
                        <li className={(currentPage === RoutePaths.corporate && !showWho) ? 'active-menu' : ''}>
                            <Link to={RoutePaths.corporate} onClick={(e)=> handleMenuClickHeader(e)}>Corporate</Link>
                        </li>
                        <li className={(currentPage === RoutePaths.whoweare || currentPage === RoutePaths.blogs || showWho)  ? 'active-menu' : ''} onClick={(e)=> handleMenuClickHeader(e, true)}>
                            <p>Who we are</p>
                            {showWho && <div className="subMenu">
                                <Link className="subMenuLink" to={RoutePaths.whoweare} onClick={(e)=> handleMenuClickHeader(e)}>About us</Link>
                                <Link className="subMenuLink" to={RoutePaths.blogs} onClick={(e)=> handleMenuClickHeader(e)}>Blogs</Link>
                            </div>}
                        </li>
                        <li className={(currentPage === RoutePaths.contact && !showWho) ? 'active-menu' : ''}>
                            <Link to={RoutePaths.contact} onClick={(e)=> handleMenuClickHeader(e)}>Contact</Link>
                        </li>
                    </ul>
                </nav>
                <div onClick={(e)=> handleMenuClickHeader(e)} className="close-menu">
                    <img src="images/close.png" alt="close"/>
                </div>
            </div>
            
        </div>
    )
}

export default Header;