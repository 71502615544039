import React, {useEffect} from "react"
import {Helmet} from "react-helmet";
import "./corporate.css"
import Button from "../../components/button";
import CorporateCard from './corporate-card';
const Corporate = ()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <Helmet>
                <title>Ambulance employee benefits | Service facilities</title>
                <meta name="description" content="Bhavya ambulance services provides the best facilities for customers and also gives best benefits and services for Corporate employees" />
                <meta name="keywords" content="Bhavya ambulance services in hyderabad" />
            </Helmet>
        <div className="corp-page">
            <h1 style={{display: "none"}}>Corporate Employee Safety Initiative: Personalized Ambulance Services in Hyderabad</h1>
            <div className="corp-top-banner">
                <div className="corp-top-left-section">
                    <img src="images/corp-banner.svg" />
                    <div className="corp-top-bottom-cont">
                        <div className="corp-users-count">
                            <p className="corp-user-number">4056 +</p>
                            <p className="corp-user-desc">Satisfied Customers</p>
                        </div>
                        <div className="corp-top-user-img">
                            <img src="images/corp-users.svg" />
                        </div>
                    </div>
                </div>
                <div  className="corp-top-right-section">
                    <p className="corp-right-title">Your Trusted Corporate Partner for Medical Transportation</p>
                    <p className="corp-right-desc">When it comes to corporate healthcare solutions, Bhavya Ambulance Services stands out as a reliable and dedicated partner. We understand the critical importance of prompt and efficient medical transportation in corporate settings, and we are here to provide top-notch ambulance services to cater to your specific needs.</p>
                    <div className="corp-top-card">
                        <div className="corp-top-card-img">
                            <img src="images/top-card-1.png" />
                        </div>
                        <div className="corp-top-card-desc">
                            <p className="corp-top-card-title">Why Choose Bhavya Ambulance?</p>
                            <p className="corp-top-card-des">Prioritize the well-being of your employees by having a dependable medical transportation partner in place</p>
                        </div>
                    </div>
                    <div className="corp-top-card">
                        <div className="corp-top-card-img">
                            <img src="images/top-card-2.png" />
                        </div>
                        <div className="corp-top-card-desc">
                            <p className="corp-top-card-title">How Can We Assist?</p>
                            <p className="corp-top-card-des">Prioritize the well-being of your employees by having a dependable medical transportation partner in place.</p>
                        </div>
                    </div>
                    <Button
                        buttonText="Get Started"
                        onClickHandler=""
                        buttonType="primary"
                        type="link"
                        navigateUrl="/contact"
                    />
                </div>

            </div>
            <div className="corp-bottom-banner">
                <p className="corp-bottom-title">Corporate Services</p>
                <div className="corp-card-section">
                    <CorporateCard
                        title="Round-the-clock Availability"
                        badgeText="24/7 Availability"
                        description="Medical emergencies can happen at any time. Bhavya Ambulance Services ensures round-the-clock availability, guaranteeing that your employees and stakeholders receive immediate medical assistance."
                        buttonText="Get Started"
                        imageUrl="images/corp-ambulance.png"
                    />
                    <CorporateCard
                        title="Skilled in handling medical emergencies."
                        badgeText="Professional Team"
                        description="Our team consists of experienced paramedics and drivers who are skilled in handling medical emergencies. They are equipped to provide immediate care and ensure a smooth transition to the hospital."
                        buttonText="Get Started"
                        imageUrl="images/life-support.png"
                    />
                    <CorporateCard
                        title="Advanced Medical Equipment "
                        badgeText="State-of-the-Art Equipment"
                        description="We maintain a modern fleet of ambulances, fully equipped with advanced medical equipment to meet the needs of a variety of medical conditions and emergencies."
                        buttonText="Get Started"
                        imageUrl="images/phone.png"
                    />
                    <CorporateCard
                        title="Safety is our Utmost Priority"
                        badgeText="Safety First"
                        description="At Bhavya Ambulance Services, safety is our utmost priority. We follow strict safety protocols and maintain our ambulances to the highest standards."
                        buttonText="Get Started"
                        imageUrl="images/helicopter.png"
                    />
                    <CorporateCard
                        title="responds swiftly to any emergency call"
                        badgeText="Rapid Response"
                        description="Our well-coordinated team responds swiftly to any emergency call, ensuring that your employees receive timely medical attention."
                        buttonText="Get Started"
                        imageUrl="images/phone-ambulance.png"
                    />
                    <CorporateCard
                        title="Organization's Specific Needs"
                        badgeText="Customized Solutions"
                        description="We understand that each corporate entity has unique requirements. That's why we offer customizable solutions to match your organization's specific needs and budget."
                        buttonText="Get Started"
                        imageUrl="images/lights.png"
                    />
                </div>
                
            </div>

        </div>
        </>

    )
}

export default Corporate;