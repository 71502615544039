import Accordion from 'react-bootstrap/Accordion';
import './serviceAccordion.css';

const ServiceAccordion = ()=>{
    return (
      <div className='service-accordion'>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Swift Response</Accordion.Header>
          <Accordion.Body>
          Time is of the essence in critical medical emergencies. Our Air Ambulance services are designed to provide rapid response and quick transportation, ensuring that patients receive timely medical attention.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Expert Medical Team</Accordion.Header>
          <Accordion.Body>
          Our Air Ambulance services are staffed by a highly skilled and experienced medical team. They are equipped to handle a wide range of medical conditions and provide the necessary care during transit.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>State-of-the-Art Equipment</Accordion.Header>
          <Accordion.Body>
          Our Air Ambulances are equipped with the latest medical equipment and technology, including advanced life support systems, to ensure the highest level of care during transportation.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Comprehensive Coverage</Accordion.Header>
          <Accordion.Body>
          We offer Air Ambulance services not only within Hyderabad but also to destinations across India. No matter where you are, we can provide you with the necessary air transportation.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Safety First</Accordion.Header>
          <Accordion.Body>
          Our Air Ambulances adhere to strict safety protocols and guidelines to ensure a secure and comfortable journey for the patient.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>24/7 Availability</Accordion.Header>
          <Accordion.Body>
          Medical emergencies can happen at any time. That's why we offer round-the-clock Air Ambulance services in Hyderabad. You can rely on us day or night.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>Transparent Pricing</Accordion.Header>
          <Accordion.Body>
          We believe in transparent and affordable pricing for our Air Ambulance services. You'll know exactly what to expect without hidden fees or surprises.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      </div>
    );
  }
  
  export default ServiceAccordion;