import React from "react"
import './explore-service-card.css'

const ExploreServiceCard = ({imageUrl, title, description, link}) => {
    return (
        <div className="explore-service-card-content">
            <img src={imageUrl} alt="image" />
            <div className="explore-service-card-description">
                <p className="explore-card-tile">{title}</p>
                <p className="explore-description">{description}</p>
                <a className="explore-link" href={link}>Learn More <img src="images/ArrowRight.png" /></a>
            </div>
            
        </div>
    )
}

export default ExploreServiceCard;