import React, {useState, useEffect} from "react"
import emailjs from '@emailjs/browser';
import {Helmet} from "react-helmet";
import "../../common/css/global.css"
import PhoneNumber from "../../components/phone-number"
import "./contact.css"
const Contact = ()=> {
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [reason, setReason] = useState('Emergency')
    const [message, setMessage] = useState('')
    const [messageError, setMessageError] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const allLetter=(inputtxt) => {
     const letters = /^[a-zA-Z\s]*$/;
     if(inputtxt.match(letters))
       {
        return true;
       }
     else
       {
        setNameError(true)
       return false;
       }
    }
    const checkEmail =(value) => {
        let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
        return regex.test(value)
    }
    const inputValidation =(inputValue) => {
        const letterNumber = /^[0-9a-zA-Z\s]*$/;
        if(inputValue.match(letterNumber)) {
        return true;
        }
        else
        {  
        return false; 
        }
    }
    const handleInputChange = (e, field) => {
        if (field === 'name') {
            setNameError(false)
            allLetter(e.target.value) && setName(e.target.value)
            
        } else if (field === 'email') {
            setEmailError(false);
            checkEmail(e.target.value) ? setEmail(e.target.value) : setEmailError(true);
        }else if (field === 'message') {
            setMessageError(false) 
            inputValidation(e.target.value) ? setMessage(e.target.value) : setMessageError(true) 
        } else if (field === 'reason') {
            setReason(e.target.value)
        }
    }
    const submitEvent =(e)=> {
        e.preventDefault();
        if (!email) setEmailError(true)
        if (!name) setNameError(true)
        if(!message) setMessageError(true)
        // if(!subject) setSubjectError(true)
        // console.log(email, name, subject, message)
        emailjs.send("service_7odhtak","template_rvdaima",{
            from_name: `${name}`,
            email: `${email}`,
            message: `${message}`,
            }, "Bhi52lJg0ytpgay-x").then((result) => console.log(), (error) => console.log());
    }
    return (
        <>
        <Helmet>
            <title>Contact us | ambulance services near me</title>
            <meta name="description" content="Contact Us for Emergency Number Ambulance Services - Swift, Reliable, and Professional. Call Bhavya Ambulance services- 9318108108 for Immediate Assistance in Hyderabad" />
            <meta name="keywords" content="Emergency Number ambulance services" />
        </Helmet>
        <h1 style={{display: "none"}}>Contact Us | Ambulance service near me Hyderabad</h1>
        <div className="page-contact">
            <div className="page-banner">
                <img className="contact-banner-img" src="images/contact-us-banner.svg" alt="banner"/>
                <div className="contact-page-caption">
                    <h2>Contact Us</h2>
                    <p className="hide-mobile">In a medical emergency, you can reach us at our dedicated hotline for Ambulance services in Hyderabad. Our team is ready to assist you 24/7, ensuring that you get the help you need when you need it the most.</p>
                    <PhoneNumber />
                </div>
                <div className="contact-form">
                    <div className="contact-get-in-touch">
                        <p className="contact-get-header">Get in touch</p>
                        <div className="contact-email-icon-section">
                            <div className="contact-icon-img"><img src="images/email.png" /></div>
                            <div className="contact-get-content">
                                <p>EMAIL US</p>
                                <p>sreenath@bhavyaaambulance.com</p>
                            </div>
                        </div>
                        <div className="contact-phone-icon-section">
                            <div className="contact-icon-img"><img src="images/phone-icon.png" /></div>
                            <div className="contact-get-content">
                                <p>PHONE NUMBER</p>
                                <p>9948852352</p>
                            </div>
                        </div>
                        <div className="contact-address-icon-section">
                            <div className="contact-icon-img"><img src="images/location.png" /></div>
                            <div className="contact-get-content">
                                <p>3rd Floor, Bhargavi Complex, Calvary Temple Rd, IDPl Employees Colony, Hafeezpet, Miyapur, Hyderabad, Telangana 500049</p>
                            </div>
                        </div>
                        <div className="social-media-section">
                            <p>Connect with us:</p>
                            <div className="social-media-images">
                                <a href="https://www.facebook.com/people/Bhavya-Ambulance-Services-in-Hyderabad/100094683977601/?mibextid=ZbWKwL" target="_blank"><img className="social-media-icons" src="images/facebook.png" /></a>
                                <a href="https://x.com/BAmbulance15791?t=rjWJ5KJ9k3wkYdOaikKXuQ&s=09" target="_blank"><img className="social-media-icons" src="images/twitter.png" /></a>
                                <a href="https://www.linkedin.com/in/bhavya-ambulance-services/" target="_blank"><img className="social-media-icons" src="images/linkedin.png" /></a>
                                <a href="https://www.instagram.com/bhavyaambulanceservices/?igshid=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank"><img className="social-media-icons" src="images/instgram.png" /></a>
                                {/* <a target="_blank"><img className="social-media-icons" src="images/web.png" /></a> */}
                            </div>
                        </div>
                    </div>


                    <div className="contact-email-section">
                        <div className="contact-email-name-section">
                            <div className="contact-input-field">
                                <label className="contact-label">Name</label>
                            <input type="text" placeholder="Full name" required  onChange={ (e) => handleInputChange(e, "name") }/>
                {nameError && <p className="error-msg">Please enter your name. Only alphabets are allowed.</p>}
                            </div>
                            <div className="contact-input-field ml-15">
                            <label className="contact-label">Email</label>
                            <input type="email" placeholder="Email address" required  onChange={ (e) => handleInputChange(e, "email") }/>
                {emailError && <p className="error-msg">Please enter valid email.</p>}
                            </div>
                        </div>
                        <div className="mt-20">
                            <label className="contact-label">Select Ambulance</label>
                            <select name="ambulance" id="ambulance" onChange={(e) => handleInputChange(e, "reason")} value={reason} className="select-ambulance">
                                <option value="emergency">Emergency</option>
                                <option value="hospitalvisit">Hospital visit</option>
                                <option value="others">Others</option>
                            </select>
                        </div>
                        <div className="contact-phone-field mt-20">
                            <label className="contact-label">Phone Number</label>
                            <input type="text" placeholder="Enter phone number" required  onChange={ (e) => handleInputChange(e, "phone") }/>
                {emailError && <p className="error-msg">Please enter valid phone  number.</p>}
                        </div>
                        <div className="contact-message-field mt-20">
                            <label className="contact-label">Message</label>
                            <textarea placeholder="Your message *"></textarea>
                        </div>
                        <div className="submit-section">
                            <button type="submit" onClick={(e) => submitEvent(e)} className="email-submit-button">Send Messages</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="conatct-email-section">
                <p className="email-heading">What can us do for you?</p>
                <div className="heading-dash"/>
                <p className="email-text">We are ready to work on a project of any complexity, whether it’s commercial or residential.</p>
                
                    <div className="email-name fleft">
                    <input type="text" placeholder="Your name *" required  onChange={ (e) => handleInputChange(e, "name") }/>
                {nameError && <p className="error-msg">Please enter your name. Only alphabets are allowed.</p>}
                    </div>
                <div className="email-field ml30 fright">
                
                </div>
                
                <input type="text" placeholder="Subject *" required className="email-subject" onChange={ (e) => handleInputChange(e, "subject") }/>
                {subjectError && <p className="error-msg">Please enter subject. Only alphabets and numbers are allowed.</p>}
                <textarea placeholder="Messages *" className="email-message" onChange={ (e) => handleInputChange(e, "message") }/>
                {messageError && <p className="error-msg">Please enter message. Only alphabets and numbers are allowed.</p>}
                <button type="submit" onClick={(e) => submitEvent(e)} className="email-submit-button">Submit Messages</button>
            
            </div> */}
            
        </div>
        </>
    )
}

export default Contact;