import React  from "react"


const EmbalmingServices =()=> {
    return (
        <div>
            <br />
            <div className="page-service-des">
                Embalming is a method of preventing decomposition in your loved one by infusing chemicals and liquids. Though dressing is a personal preference, the goal is generally to make the deceased appear appropriate for a religious ceremony or funeral service. Embalming services are required when the body is transported by train or air. Another motive for having your loved one embalmed is to spend more time with them and say farewell. Seeing your loved one restored offers the impression of restful sleep and health. The procedure of embalming services begins with prepping the body. Because it is a surgical procedure, the body is initially disinfected. The limbs are then massaged to help reduce stiffness. The two primary phases of embalming are then performed: establishing the face features and artery or cavity embalming.
                <br />
                <br />
                <h4><b>The Embalming Process</b></h4>
                Arterial embalming entails withdrawing blood through veins and replacing it with the embalming fluid. In contrast, with cavity embalming procedures, a small incision is made in the lower abdomen to remove the body's gas and fluid content. It is then substituted with chemical combinations based on formaldehyde. The embalming services are very surgical, requiring extensive preparation and resources. Because it is a highly technical field, only experienced professionals should be hired. Our team guarantees the highest quality of service.
                <br /><br /><h5><b>Last Journey provides the following Embalming Services</b></h5>
                <ul style={{ marginLeft: "40px" }}>
                    <li>Embalmers are both certified and professional.</li>
                    <li>Getting the body ready for embalming.</li>
                    <li>Setting up of Facial Features.</li>
                    <li>Arterial and Cavity Embalming.</li>
                    <li>Cosmetic preparation includes makeup, hair, and clothing.</li>
                    <li>Certificate for coffin-making</li>
                    <li>Transportation services.</li>
                    <li>Air cargo</li>
                </ul>
                Our team operates with the highest care and concern. We want you to be able to mourn in peace while we take care of everything, including embalming. The Last Journey staff is constantly striving to provide you with a fulfilling experience, and we ensure you that all of your needs will be met. Please get in touch with us if you want to book embalming services in Hyderabad. We offer full embalming services, including coffin maker certificates and transportation.
            </div>
        </div>
    )
}

export default EmbalmingServices;