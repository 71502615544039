import React, {useEffect} from "react"
import "../../common/css/global.css"
import Blogs from '../../components/blogs'
import "./blogDetails.css"

const BlogDetails = ()=> {
    return (
        <div className="page-whowe-are">
            <div className="page-banner">
                <img src="images/whoweare.png" alt="banner"/>
                <div className="page-caption page-caption-whoweare">
                    <p className="all-caps hide-mobile">September 30, 2023 |   surendra</p>
                    <h3 className="mt-15">Managed by board certified  technicians, paramedics and medical team.</h3>
                </div>
            </div>
            <div className="whoweare-section component-layout">
                <div className="whoweare-content">
                    <div className="whoweare-content-text">
                    Bhavya Ambulance Services boasts a fleet comprising the fastest and most well-equipped ambulances in Hyderabad, staffed by highly trained medical professionals. We strive to provide the most comfortable patient transportation experience, offering the reliability of a trusted friend. Our mission is rooted in serving humanity, and we are committed to ensuring that no one loses their life due to delayed ambulance response.
                    </div>
                    <div className="whoweare-content-title mt-15 mb-10">
                    Why Choose Bhavya Ambulance Services for Air Ambulance in Hyd?
                    </div>
                    <div className="whoweare-content-text">
                    It is crucial to be acquainted with dependable ambulance service providers in your vicinity. Bhavya Ambulance Services in Hyderabad is here to provide you with immediate access to the right emergency ambulance contact number. We aim to instill a sense of security within the city, assuring everyone that "when an emergency strikes, our ambulance services will be there to assist." With the finest medical staff in Hyderabad, we take pride in delivering exceptional ambulance services.
                    </div>
                    <div className="whoweare-services">
                        <div className="whoweare-services-card">
                            <div className="whoweare-services-card-image">
                                <img src="images/24-hours.png" alt="image" />
                            </div>
                            <div className="whoweare-services-content">
                                <p className="whoweare-services-content-title">24/7 Availability</p>
                                <p className="whoweare-services-content-description">Bhavya Ambulance Services boasts a fleet comprising the fastest ambulances in Hyderabad</p>
                            </div>
                        </div>
                        <div className="whoweare-services-card">
                            <div className="whoweare-services-card-image">
                                <img src="images/ambulance-1.png" alt="image" />
                            </div>
                            <div className="whoweare-services-content">
                                <p className="whoweare-services-content-title">Emergency Ambulance</p>
                                <p className="whoweare-services-content-description">We strive to provide the most comfortable patient transportation experience</p>
                            </div>
                        </div>
                        <div className="whoweare-services-card">
                            <div className="whoweare-services-card-image">
                                <img src="images/emergency-services.png" alt="image" />
                            </div>
                            <div className="whoweare-services-content">
                                <p className="whoweare-services-content-title">Reliable Ambulance</p>
                                <p className="whoweare-services-content-description">It is crucial to be acquainted with dependable ambulance service</p>
                            </div>
                        </div>
                        <div className="whoweare-services-card">
                            <div className="whoweare-services-card-image">
                                <img src="images/customer-service.png" alt="image" />
                            </div>
                            <div className="whoweare-services-content">
                                <p className="whoweare-services-content-title">Comprehensive Services</p>
                                <p className="whoweare-services-content-description">Bhavya Ambulance Services in Hyderabad offers a comprehensive range of options</p>
                            </div>
                        </div>
                    </div>
                    <div className="whoweare-content-title mt-15 mb-10">
                    How to Reach Us for Air Ambulance Services
                    </div>
                    <div className="whoweare-content-text">
                    <p>1. Swift Response: Time is of the essence in critical medical emergencies. Our Air Ambulance services are designed to provide rapid response and quick transportation, ensuring that patients receive timely medical attention.</p>
                    <p>2. Expert Medical Team: Our Air Ambulance services are staffed by a highly skilled and experienced medical team. They are equipped to handle a wide range of medical conditions and provide the necessary care during transit.</p>
                    <p>3. State-of-the-Art Equipment: Our Air Ambulances are equipped with the latest medical equipment and technology, including advanced life support systems, to ensure the highest level of care during transportation.</p>
                    <p>4. Comprehensive Coverage: We offer Air Ambulance services not only within Hyderabad but also to destinations across India. No matter where you are, we can provide you with the necessary air transportation.</p>
                    <p>5. Safety First: Safety is our top priority. Our Air Ambulances adhere to strict safety protocols and guidelines to ensure a secure and comfortable journey for the patient.</p>
                    <p>6. 24/7 Availability: Medical emergencies can happen at any time. That's why we offer round-the-clock Air Ambulance services in Hyderabad. You can rely on us day or night.</p>
                    <p>7. Transparent Pricing: We believe in transparent and affordable pricing for our Air Ambulance services. You'll know exactly what to expect without hidden fees or surprises.</p>
                    </div>
                </div>
                <Blogs />
            </div>
            
            
        </div>
    )
}

export default BlogDetails;