import React from "react";
import { Carousel } from 'react-bootstrap';
import "./Carousel.css"

const CustomCarousel = () => {
    return (
        <div className="custom-carousel">
            <Carousel controls={false}>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="images/home-ambulance.svg"
                    alt="First slide"
                    />
                    <Carousel.Caption>
                        <p className="caption-title">Your helath is our priority</p>
                        <h3>Emergency Ambulance Services in Hyderabad</h3>
                        <p className="hide-mobile">Our 24/7 ambulance services are here to serve Hyderabad. Count on our services to prioritize patient safety and comfort.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="images/home-ambulance.svg"
                    alt="Second slide"
                    />

                    <Carousel.Caption>
                        <p className="caption-title">Provide you with immediate access</p>
                        <h3>Reliable Ambulance Services Near You in Hyderabad</h3>
                        <p className="hide-mobile">It is crucial to be acquainted with dependable ambulance service providers in your vicinity. Bhavya Ambulance Services in Hyderabad.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="images/home-ambulance-2.svg"
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                        <p className="caption-title">Intensive medical support during transport</p>
                        <h3>Critical Care and ICU Ventilator Ambulance Services</h3>
                        <p className="hide-mobile">In addition to our standard services, Bhavya Ambulance Services offers critical care and ventilator ambulance services in Hyderabad.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                </Carousel>
        </div>
    )
}

export default CustomCarousel;