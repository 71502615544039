import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet";
import ServiceAccordion from '../../components/serviceAccordion'
import ExploreServiceCard from "../../components/exploreServiceCard"
import PhoneNumber from "../../components/phone-number"
import "../../common/css/global.css"
import servicepage from  "../../common/constant"
import { RoutePaths } from "../../constant"; 
import EmbalmingServices from "./embalmingService";
import FreezerBox from "./freezerBox"
import IcuVentialtor from "./icuVentialtor"
import RoadAmbulance from "./roadAmbulance"
import StandByAmbulance from "./standByAmbulance"
import "./services.css"
const Services = ({ id, title, meta, keyword, h1 })=> {
    const [dataText, setData] = useState({});
    const service = id;
    useEffect(() => {
        setData(servicepage[service]);
        window.scrollTo(0, 0)
    }, [service])
    return (
        <>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={meta} />
            <meta name="keywords" content={keyword} />
        </Helmet>
            <PhoneNumber isSticky />
            <h1 style={{display: "none"}}>{h1}</h1>
            
        <div className="page-services sticky-page">
            <div className="page-banner">
                <img src="images/air-ambulance-banner.svg" alt="banner"/>
                <div className="page-caption">
                    <p className="hide-mobile">When every moment counts in a medical emergency</p>
                    <div className="page-divider hide-mobile"></div>
                    <h2 className="mt-15">{dataText.bannerTitle}</h2>
                </div>
            </div>
            <div className="page-service-section">
                <div className={`page-service-heading-section  ${id !="airAmbulance" ? 'text-left' : ''}`}>
                    <p className="service-head-text">Immediate Medical Transportation</p>
                    <div className="service-underline"></div>
                    <div className={`page-service-title  ${id!="airAmbulance" ? 'margin-0' : ''}`}>
                    {dataText.title}</div>
                    <div className="page-service-des">{dataText.description}</div>

                    {id==="embalingServices" && <EmbalmingServices />}
                    {id==="freezerBox" && <FreezerBox />}
                    {id==="icuVentilator" && <IcuVentialtor />}
                    {id==="roadAmbulance" && <RoadAmbulance />}
                    {id==="standByAmbulance" && <StandByAmbulance />}
                </div>
                
                {id=="airAmbulance" ? <div className="page-service-offered-section">
                    <ServiceAccordion />
                    <div className="page-service-offered-image">
                        <img src="images/service-page-image.svg" />
                        <p className="page-service-image-des">When it comes to critical medical emergencies, trust Bhavya Ambulance Services for top-notch Air Ambulance services in Hyderabad. We are committed to saving lives and providing the highest standard of care during air transportation. Your well-being is our priority, and we're here to serve you in your time of need.</p>
                    </div>
                </div> : ''}
            </div>
            <div className="explore-service-section">
                <div className="page-service-heading-section">
                        <div className="page-service-title">Explore Our Services</div>
                        <div className="page-service-des">We have taken extreme measures to ensure seamless patient transport services accounting the city traffic, availability of medical staff and possible uncertainties.</div>
                        
                    </div>
                <div className="explore-service-card-section">
                    <ExploreServiceCard
                        imageUrl="images/explore-1.png"
                        title="ICU VENTILATOR"
                        description="In a medical emergency, you can reach us at our dedicated hotline for Air Ambulance services in Hyderabad. "
                        link={RoutePaths.icuVentilator} 
                    />
                    <ExploreServiceCard
                        imageUrl="images/explore-2.png"
                        title="ROAD TRANSPORT"
                        description="When it comes to road ambulance services in Hyderabad, Bhavya Ambulance Services is your reliable partner."
                        link={RoutePaths.roadAmbulance}
                    />
                    <ExploreServiceCard
                        imageUrl="images/explore-3.png"
                        title="EMBALMING SERVICES"
                        description="With Bhavya Ambulance Services, you can trust that your loved ones will receive the best medical care during train ambulance transportation."
                        link={RoutePaths.embalingServices}
                    />
                    <ExploreServiceCard
                        imageUrl="images/explore-4.png"
                        title="FREEZER BOX AMBULANCE "
                        description="Our Freezer Box Ambulance services in Hyderabad are equipped with state-of-the-art refrigeration units to maintain the necessary temperature."
                        link={RoutePaths.freezerBox}
                    />
                    <ExploreServiceCard
                        imageUrl="images/explore-5.png"
                        title="STANDBY AMBULANCE "
                        description="In times of medical emergencies, every second counts, and having a standby ambulance service you can rely on is crucial."
                        link={RoutePaths.standByAmbulance}
                    />
                    <ExploreServiceCard
                        imageUrl="images/explore-6.png"
                        title="MORTUARY SERVICES"
                        description="we are dedicated to providing a seamless and dignified hearse ambulance service that honors the memory of your loved one. "
                        link={RoutePaths.airAmbulance}
                    />
                </div>
            </div>
        </div>
        </>
    )
}

export default Services;