import React from "react";
import "../../common/css/global.css"
import "./footer.css"
const Footer = () => {
    return (
        <div className="footer-component">
            <div className="footer-component-links">
                <div className="footer-comp-info footer-section">
                    <img className="footer-logo" src="images/logo-transparent.png" alt="logo" />
                    <p className="footer-comp-info-text">Where can I find the fastest and most reliable ambulance services</p>
                </div>
                <div className="footer-links footer-section mobile-footer">
                    <p className="footer-header">Company</p>
                    <p className="quick-link">
                        <span className="quick-link-text">About Us</span>
                    </p>
                    <p className="quick-link">
                        <span className="quick-link-text">Our Services</span>
                    </p>
                    <p className="quick-link">
                        <span className="quick-link-text">Blogs</span>
                    </p>
                    <p className="quick-link">
                        <span className="quick-link-text">Terms of Use</span>
                    </p>
                    <p className="quick-link">
                        <span className="quick-link-text">Privacy policy</span>
                    </p>
                </div>
                <div className="footer-links footer-section mobile-footer">
                    <p className="footer-header">Our Services</p>
                    <p className="quick-link">
                        <span className="quick-link-text">Air Amubulance</span>
                    </p><p className="quick-link">
                        <span className="quick-link-text">Embalming Services</span>
                    </p>
                    <p className="quick-link">
                        <span className="quick-link-text">Freezer Box Ambulance</span>
                    </p>
                    <p className="quick-link">
                        <span className="quick-link-text">ICU Ventilator</span>
                    </p>
                    <p className="quick-link">
                        <span className="quick-link-text">Road Transport Ambulance</span>
                    </p>
                    <p className="quick-link">
                        <span className="quick-link-text">Standby Ambulance</span>
                    </p>
                </div>
                <div className="footer-reach-us footer-section">
                    <p className="footer-header">Get in Touch</p>  
                    <p className="footer-comp-info-text">3rd Floor, Bhargavi Complex, Calvary Temple Rd
                        <span>IDPl Employees Colony, Hafeezpet, Miyapur,</span>
                        <span>Hyderabad, Telangana 500049</span>
                        <span>Email: sreenath@bhavyaambulance.com</span>
                        <span>Contact: 04043463699</span>
                        </p>
                        <div className="social-media-images">
                            <a href="https://www.facebook.com/people/Bhavya-Ambulance-Services-in-Hyderabad/100094683977601/?mibextid=ZbWKwL" target="_blank" className="social-media-icons"><img  src="images/facebook.png" /></a>
                            <a href="https://x.com/BAmbulance15791?t=rjWJ5KJ9k3wkYdOaikKXuQ&s=09" target="_blank" className="mr-10" ><img src="images/twitter.png" /></a>
                            <a href="https://www.linkedin.com/in/bhavya-ambulance-services/" target="_blank" className="social-media-icons" ><img src="images/linkedin.png" /></a>
                            <a href="https://www.instagram.com/bhavyaambulanceservices/?igshid=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank" className="social-media-icons" ><img src="images/instgram.png" /></a>
                            {/* <a target="_blank"><img className="social-media-icons" src="images/web.png" /></a> */}
                        </div>
                <div className="footer-connect">

                </div>
                </div>
                
            </div>
            <div className="footer-copy-rights">
            Copyright © 2022. Powered by Bhavya Ambulance Inc
            </div>
        </div>
    )
}

export default Footer;