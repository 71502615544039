import React from "react"
import Button from '../button'
import '../../common/css/global.css'
import './product.css'

const ProductInfo = ({title, highlightTitle, subTitle, image, description, buttonText, flipProduct, navigateUrl, showAboutUsCaption}) => {
    const imageSection = () => {
        return (
            <div className="product-image-content">
                <img className="product-image" src={image} alt="ambulance" />
            </div>
        )
    }
    const descriptionSection = () => {
        const classname = flipProduct ? "product-description-content" : "product-description-content product-space"
        return (
            <div className={classname}>
                <div className="product-description-inner-content mb-40">
                    <p className="product-title"><span className="product-title-highlight">{highlightTitle}</span> {title}</p>
                    <p className="product-sub-title">{subTitle}</p>
                    <p className="product-description">{description}</p>
                    {buttonText && <Button
                        buttonType="primary"
                        buttonText={buttonText}
                        type="link"
                        navigateUrl={navigateUrl}
                    /> }
                    {showAboutUsCaption && <div className="caption-section">
                        <div className="caption-content">
                            <div className="caption-content-check">
                                <img src="images/CheckCircle.png"/>
                            </div>
                            <div className="caption-description">
                                <p className="product-caption-title">Vision</p>
                                <p className="caption-description-content">To provide ambulance services for people whose health is in critical state, offering them counselling, empathy and medical assistance on their journey towards hospitals and homes.</p>
                            </div>
                        </div>
                        <div className="caption-content">
                            <div className="caption-content-check">
                                <img src="images/CheckCircle.png"/>
                            </div>
                            <div className="caption-description">
                                <p className="product-caption-title">Mission</p>
                                <p className="caption-description-content">To save lives, reduce suffering and enhance quality of life, through the provision of accessible and responsive quality patient care and transport.</p>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
    return (
        <div className="product-content">
            {!flipProduct ? <>
                {imageSection()}
                {descriptionSection()}
            </> : <>
                {descriptionSection()}
                {imageSection()}
            </>}
            
        </div>
    )
}

export default ProductInfo;