const servicepage = {
  roadAmbulance: {
    bannerTitle: "Road Ambulance",
    title: "Fastest and Most Reliable Road Ambulance Services in Hyderabad",
    description:
      "Bhavya Ambulance Services offers top-notch road ambulance services in Hyderabad. We provide reliable and swift ambulance transportation for all your medical emergencies.",
    imageDescription:
      "When it comes to medical emergencies in Hyderabad, time is of the essence. You need a trusted partner who can provide swift and reliable road ambulance services to ensure that you or your loved ones receive the care they need promptly. That's where Bhavya Ambulance Services comes in. Why Choose Bhavya Ambulance Services for Road Ambulance Services in Hyderabad? At Bhavya Ambulance Services, we understand the critical importance of timely medical assistance. Our road ambulance services in Hyderabad are designed to cater to a wide range of medical emergencies, ensuring that you get the care you need when you need it the most.",
  },
  airAmbulance: {
    bannerTitle: "Air Ambulance",
    title: "Fastest and Most Reliable Air Ambulance Services in Hyderabad",
    description:
      "When every moment counts in a medical emergency, Bhavya Ambulance Services provides the fastest and most reliable Air Ambulance services in Hyderabad. We understand that some situations require immediate transportation with specialized medical care, and that's where our Air Ambulance services shine.",
    imageDescription:
      "When it comes to critical medical emergencies, trust Bhavya Ambulance Services for top-notch Air Ambulance services in Hyderabad. We are committed to saving lives and providing the highest standard of care during air transportation. Your well-being is our priority, and we're here to serve you in your time of need.",
  },
  icuVentilator: {
    bannerTitle: "ICU Ventilator",
    title:
      "Expert ICU Ventilator and Pediatric Ventilator Services in Hyderabad",
    description:
      "Do you require specialized pediatric and ICU ventilator services in Hyderabad? Bhavya Ambulance Service is the only place to look. Our commitment to giving our patients the best care possible stems from our understanding of how serious medical situations may be..",
    imageDescription:
      "When it comes to critical care and emergency medical transportation, Bhavya Ambulance Service stands out as a leader in Hyderabad. Our commitment to excellence, state-of-the-art equipment, and compassionate medical team make us your top choice for ICU and pediatric ventilator services. Contact us today for immediate assistance and peace of mind during medical emergencies. Your health and well-being are our top priorities.",
  },
  embalingServices: {
    bannerTitle: "Embalming Services",
    title: "Expert Embalming Services in Hyderabad",
    description:
      "Bhavya Ambulance Services is a trusted name in the healthcare and embalming services in Hyderabad. With years of experience and a team of skilled professionals, we offer a range of services dedicated to providing support and assistance during difficult times.",
    imageDescription:
      "When it comes to critical care and emergency medical transportation, Bhavya Ambulance Service stands out as a leader in Hyderabad. Our commitment to excellence, state-of-the-art equipment, and compassionate medical team make us your top choice for ICU and pediatric ventilator services. Contact us today for immediate assistance and peace of mind during medical emergencies. Your health and well-being are our top priorities.",
  },
  freezerBox: {
    bannerTitle: "Freezer Box",
    title: "Freezer Box Ambulance Services in Hyderabad",
    description:
      "When it comes to providing reliable and efficient Freezer Box Ambulance services in Hyderabad, Bhavya Ambulance Services stands out as the trusted name. We understand the critical need for preserving and transporting deceased loved ones with dignity and care. Our Freezer Box Ambulance services are designed to offer peace of mind during these challenging times.",
    imageDescription:
      "Bhavya Ambulance Services is committed to ensuring the respectful and compassionate transportation of the deceased. Our Freezer Box Ambulance services in Hyderabad are equipped with state-of-the-art refrigeration units to maintain the necessary temperature, preserving the body's integrity. We have a team of trained professionals who handle every aspect of the process with sensitivity and professionalism.",
  },
  standByAmbulance: {
    bannerTitle: "StandBy Ambulance",
    title: "StandBy Ambulance Services in Hyderabad",
    description:
      "Bhavya Ambulance Services is your trusted source for Standby Ambulance services in Hyderabad. In times of medical emergencies, every second counts, and having a standby ambulance service you can rely on is crucial. Our dedicated team at Bhavya Ambulance Services is committed to ensuring your safety and well-being by providing swift and efficient standby ambulance services in Hyderabad.",
    imageDescription:
      "Bhavya Ambulance Services is committed to ensuring the respectful and compassionate transportation of the deceased. Our Freezer Box Ambulance services in Hyderabad are equipped with state-of-the-art refrigeration units to maintain the necessary temperature, preserving the body's integrity. We have a team of trained professionals who handle every aspect of the process with sensitivity and professionalism.",
  },
};

export default servicepage;
