import React from "react"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../../common/css/global.css'
import './partners.css'

const Partners = () => {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 8,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 768 },
          items: 5,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 767, min: 464 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
      const sliderImageUrl = [
        //First image url
        {
          url:
            "images/yashoda.png"
        },
        {
          url:
            "images/medicover.png"
        },
        //Second image url
        {
          url:
            "images/kims.png"
        },
        //Third image url
        {
          url:
            "images/aster.png"
        },
      
        //Fourth image url
      
        {
          url:
            "images/century.png"
        },
        {
            url:
              "images/apollo.png"
          },
          {
            url:
              "images/citizen.png"
          },
          {
            url:
              "images/omega.png"
          }
      ];
    return (
        <div className="partners-section">
            <p className="partners-title">Trusted by Over <b>50+ Hospitals</b></p>
            <div className="">
            <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={false}
                infinite={true}
                partialVisible={true}
                autoPlaySpeed={1000}
                transitionDuration={500}
                dotListClass="custom-dot-list-style"
            >
                {sliderImageUrl.map((imageUrl, index) => {
                return (
                    <div className="slider" key={index}>
                    <img src={imageUrl.url} alt="movie" />
                    </div>
                );
                })}
            </Carousel>
                {/* <img src="" alt="yashoda hospitals" />
                <img src="" alt="medicover" />
                <img src="" alt="kims" />
                <img src="" alt="aster" />
                <img src="" alt="century" />
                <img src="" alt="apollo" />
                <img src="" alt="citizens" />
                <img src="images/omega.png" alt="omega" /> */}
            </div>
        </div>
    )
}

export default Partners;


