import React  from "react"
import "./services.css"

const IcuVentialtor =()=> {
    return (
        <div>
            <br />
            <div className="page-service-des">
                <h5><b>Our ICU Ventilator Services:</b></h5>
                <ul style={{ marginLeft: "40px" }}>
                    <li><b>State-of-the-Art Equipment: </b>Bhavya Ambulance Service is equipped with cutting-edge ICU ventilators that are designed to meet the unique needs of critically ill patients. Our ventilators are maintained to the highest standards to ensure optimal performance.</li>
                    <li><b>Experienced Medical Team: </b>Our skilled medical professionals are trained in the operation and monitoring of ICU ventilators. They have the expertise to provide personalised care, making sure that each patient receives the attention they require.</li>
                    <li><b>24/7 Availability: </b>Medical emergencies can happen at any time. That's why we offer round-the-clock ICU ventilator services. Whether it's day or night, Bhavya Ambulance Service is here to assist you.</li>
                </ul> 
                <br />
                <h5><b>Our Pediatric Ventilator Services:</b></h5>
                <ul style={{ marginLeft: "40px" }}>
                    <li><b>Pediatric-Focused Care: </b>We understand that caring for pediatric patients requires a different approach. Our pediatric ventilator services are tailored to the unique needs of children, ensuring their comfort and well-being.</li>
                    <li><b>Child-Friendly Atmosphere: </b>Our ambulances are designed to create a child-friendly and reassuring environment. We strive to minimise stress and anxiety for both the child and their parents during transportation.</li>
                    <li><b>Expert Pediatric Team: </b>Our medical team includes pediatric specialists who are experienced in the use of pediatric ventilators. Your child will be in the hands of compassionate professionals who prioritize their safety and care.</li>
                </ul>	
                <br />
                <h5><b>Why Choose Bhavya Ambulance Service?</b></h5>
                <ul style={{ marginLeft: "40px" }}>
                    <li><b>Quick Response: </b>We understand that time is of the essence in emergencies. Our rapid response team is always ready to dispatch an ambulance equipped with the necessary ventilators.</li>
                    <li><b>Trusted Reputation: </b>Bhavya Ambulance Service has built a solid reputation for reliability and excellence in Hyderabad. We have successfully transported numerous critical patients, earning the trust of our community.</li>
                    <li><b>Affordable Services: </b>Quality healthcare should be accessible to all. We offer competitive rates for our ICU and pediatric ventilator services, ensuring that you receive top-notch care without breaking the bank.</li>
                </ul>	
                <br />
                When it comes to critical care and emergency medical transportation, Bhavya Ambulance Service stands out as a leader in Hyderabad. Our commitment to excellence, state-of-the-art equipment, and compassionate medical team make us your top choice for ICU and pediatric ventilator services.
                Contact us today for immediate assistance and peace of mind during medical emergencies. Your health and well-being are our top priorities.
            </div>
            </div>
    )
}

export default IcuVentialtor;