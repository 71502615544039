import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProductInfo from '../productInfo'
import { RoutePaths } from "../../constant";
import './services.css'
function Services() {
  return (
    <div>
        <div className='service-head'>
            <p className='service-head-text'>What We're Offering</p>
            <span className='service-underline'></span>
        </div>
        <p className='service-title'>About Our Services</p>
        <div className='service-tab-content'>
            <Tabs
                defaultActiveKey="AIR AMBULANCE"
                id="uncontrolled-tab-example"
                className="services-tab"
                >
                <Tab eventKey="AIR AMBULANCE" title="AIR AMBULANCE">
                    <ProductInfo
                        title= 'Air Ambulance services'
                        highlightTitle="We offer"
                        subTitle='Air Ambulance Services in Hyderabad'
                        image='images/air-ambulance.svg'
                        description="When every moment counts in a medical emergency, Bhavya Ambulance Services provides the fastest and most reliable Air Ambulance services in Hyderabad."
                        buttonText="Read More..."
                        navigateUrl={RoutePaths.airAmbulance}
                    />
                </Tab>
                <Tab eventKey="ICU VENTILATOR" title="ICU VENTILATOR">
                    <ProductInfo
                        title= 'ICU Ventilator services'
                        highlightTitle="We offer"
                        subTitle='ICU Ventilator Ambulance Services in Hyd'
                        image='images/icu-ventilator.svg'
                        description="When every second counts during a medical emergency, you need the assurance of reliable and swift ICU Ventilator ambulance services in Hyderabad."
                        buttonText="Read More..."
                        navigateUrl={RoutePaths.icuVentilator}
                    />
                </Tab>
                <Tab eventKey="ROAD TRANSPORT" title="ROAD TRANSPORT">
                    <ProductInfo
                        title= 'Road Ambulance services'
                        highlightTitle="We offer"
                        subTitle='Road Ambulance Ambulance Services in Hyd'
                        image='images/road-transport.svg'
                        description="When it comes to medical emergencies in Hyderabad, time is of the essence. You need a trusted partner who can provide swift and reliable road ambulance services..."
                        buttonText="Read More..."
                        navigateUrl={RoutePaths.roadAmbulance}
                    />
                </Tab>
                <Tab eventKey="EMBALMING SERVICES" title="EMBALMING SERVICES">
                    <ProductInfo
                        title= 'Embalming services'
                        highlightTitle="We offer"
                        subTitle='Expert Embalming Services in Hyderabad'
                        image='images/emabling-services.svg'
                        description="Embalming is a meticulous and respectful process of preserving the deceased body, ensuring that it remains in a dignified condition until the funeral or viewing."
                        buttonText="Read More..."
                        navigateUrl={RoutePaths.roadAmbulance}
                    />
                </Tab>
                <Tab eventKey="FREEZER BOX AMBULANCE " title="FREEZER BOX AMBULANCE ">
                    <ProductInfo
                        title= ' Freezer Box Ambulance'
                        highlightTitle="We offer"
                        subTitle='Freezer Box Ambulance Services in Hyd'
                        image='images/freezer-box.svg'
                        description="When it comes to providing reliable and efficient Freezer Box Ambulance services in Hyderabad, Bhavya Ambulance Services stands out as the trusted name."
                        buttonText="Read More..."
                        navigateUrl={RoutePaths.freezerBox}
                    />
                </Tab>
            </Tabs>
        </div>
    </div>
    
  );
}

export default Services;