import './services-offered.css'

function ServicesOffered() {
  return (
    <div className='services-offered-content'>
        <div className='services-offered-content-row'>
            <div className='services-cell border-right'>
                <img style={{width: "75px", marginBottom: "18px"}} src="images/happy-patients.gif" alt="patients" />
                <img src="images/happy-patients.svg" alt="patients" />
            </div>
            <div className='services-cell border-right'>
                <img style={{width: "75px", marginBottom: "18px"}}  src="images/our-ambulance.gif" alt="patients" />
                <img  src="images/our-ambulance.svg" alt="patients" />
            </div>
            <div className='services-cell border-right'>
                <img style={{width: "75px", marginBottom: "18px"}}  src="images/hospital-network.gif" alt="patients" />
                <img src="images/hospital-network.svg" alt="patients" />
            </div>
            <div className='services-cell '>
                <img style={{width: "75px", marginBottom: "18px"}}  src="images/icu-ambulance.gif" alt="patients" />
                <img src="images/icu-ambulances.svg" alt="patients" />
            </div>
        </div>
    </div>
    
  );
}

export default ServicesOffered;