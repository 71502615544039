import React from "react";
import { Link } from "react-router-dom";

import "./button.css"
const Button = ({buttonText, onClickHandler, buttonType, type = 'button', navigateUrl}) => {
    return (
        <div className={`custom-button custom-button-${type}`}>
            {type==='button' ? <button className={buttonType} onClick={(e) => onClickHandler()}>{buttonText}</button> :
            <Link className={buttonType} to={navigateUrl}>{buttonText}</Link>}
        </div>
        
    )
}

export default Button;