import React from "react";
import './blogs.css'
import { Link } from "react-router-dom";
import { RoutePaths } from "../../constant";

const BlogCard = ({imageUrl, title, time, date, description}) => {
    return (
        <div className="blog-card">
            <img className="blog-card-image" src={imageUrl} alt="image" />
            <div className="blog-description-section">
                <div className="blog-like-comment">
                    <div className="blog-like">
                        <span>{date}</span>
                    </div>
                    <div>
                        <span>{time || 1}min read</span>
                    </div>
                </div>
                <p className="blog-description">{title}</p>
                <div className="blog-writer">
                    {description}
                </div>
                <div className="blog-link">
                    <Link to={RoutePaths.blogDetail}>Read More</Link>
                </div>
            </div>
            
        </div>
    )
}

export default BlogCard;