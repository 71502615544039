export const RoutePaths = {
  home: "/",
  corporate: "/corporate",
  services: "/services",
  whoweare: "/aboutus",
  contact: "/contact",
  blogs: "/blogs",
  blogDetail: "/blogDetail",
  airAmbulance: "/air-ambulance-services-in-hyderabad",
  embalingServices: "/embalming-services-in-hyderabad",
  freezerBox: "/body-freezerbox-services-in-hyderabad",
  icuVentilator: "/ventilator-ambulance-services-in-hyderabad",
  roadAmbulance: "/ambulance-services-in-hyderabad",
  standByAmbulance: "/standby-ambulance-services-in-hyderabad",
};
