import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import BlogDetail from "./pages/blogs/blogDetails";
import AboutUs from "./pages/aboutUs";
import Contact from "./pages/contactUs";
import Corporate from "./pages/corporate";
import Services from "./pages/services";
import Blogs from "./pages/blogs";
import { RoutePaths } from "./constant";
import "./App.css";

const App = () => {
  const [currentPage, setCurrentpage] = useState("/");
  const [showMenu, setShowMenu] = useState(false);
  let location = useLocation();
  useEffect(() => {
    setCurrentpage(location.pathname);
  }, [location]);
  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };
  return (
    <div className={"app-container " + (showMenu ? "menu-expand" : "")}>
      <div className="app-layout">
        <Header
          handleMenuClick={handleMenuClick}
          currentPage={currentPage}
          showMenu={showMenu}
        />
        <div className="app-page-container">
          <Routes>
            <Route exact path={RoutePaths.home} element={<Home />} />
            <Route exact path={RoutePaths.corporate} element={<Corporate />} />
            <Route exact path={RoutePaths.whoweare} element={<AboutUs />} />
            <Route exact path={RoutePaths.services} element={<Services />} />
            <Route
              exact
              path={RoutePaths.airAmbulance}
              element={
                <Services
                  id="airAmbulance"
                  title="Credible Air Ambulance Services in Hyderabad | Rapid Medical Air Transport"
                  meta="Critically ill or injured people are transported quickly and safely to medical facilities using Air ambulance services in Hyderabad."
                  keyword="Air ambulance services in Hyderabad"
                  h1="Air ambulance in Hyderabad | Bhavya's Full Life support services."
                />
              }
            />
            <Route
              exact
              path={RoutePaths.embalingServices}
              element={
                <Services
                  id="embalingServices"
                  title="Embalming services in Hyderabad| Bhavya Ambulance services"
                  meta="Professional Embalming Services in Hyderabad. We offer compassionate and dignified care for your loved ones. Call Us - 9318108108"
                  keyword="Embalming services in Hyderabad"
                  h1="Body Embalming Services in Hyderabad: Ensuring Respectful Farewells with Bhavya"
                />
              }
            />
            <Route
              exact
              path={RoutePaths.freezerBox}
              element={
                <Services
                  id="freezerBox"
                  title="Body Preservation services | Dead body transportation"
                  meta="For a variety of reasons, you might need to keep the deceased person's body. All kind of services are offered by Bhavya ambulances"
                  keyword="Body preservation services"
                  h1="Body Preservation Services in Hyderabad: Bhavya Ambulance's Commitment to Dignity"
                />
              }
            />
            <Route
              exact
              path={RoutePaths.icuVentilator}
              element={
                <Services
                  id="icuVentilator"
                  title="ICU Ventilator Ambulance Services in Hyderabad"
                  meta="Get reliable ICU ventilator ambulance services in Hyderabad with Bhavya Ambulance Services. Our expert team is dedicated to providing life-saving care during emergencies. Call us now for immediate assistance."
                  keyword="ICU Ventilator Ambulance Services in Hyderabad"
                  h1="ICU Ventilator Ambulance Services in Hyderabad: Bhavya's Lifesaving services"
                />
              }
            />
            <Route
              exact
              path={RoutePaths.roadAmbulance}
              element={
                <Services
                  id="roadAmbulance"
                  title="Emergency ambulance services in Hyderabad"
                  meta="24/7 Emergency Ambulance Services in Hyderabad - Expert Care at Your Doorstep. Your Health and Safety Our Priority."
                  keyword="Emergency ambulance services in Hyderabad"
                  h1="24/7 Ambulance Services in Hyderabad: Bhavya Ambulance's Lifesaving Commitment"
                />
              }
            />
            <Route
              exact
              path={RoutePaths.standByAmbulance}
              element={
                <Services
                  id="standByAmbulance"
                  title="Standby Ambulance Services in Hyderabad Call- 9318108108"
                  meta="Opt for a standby ambulance in Hyderabad for corporates and Special events through Bhavya ambulance services in view of being emergency prepared to safeguard and keep the employees safe in times of contingencies"
                  keyword="Standby Ambulance Services in Hyderabad"
                  h1="Swift Response Standby Ambulance in Hyderabad by Bhavya Ambulance"
                />
              }
            />

            <Route exact path={RoutePaths.contact} element={<Contact />} />
            <Route exact path={RoutePaths.blogs} element={<Blogs />} />
            <Route
              exact
              path={RoutePaths.blogDetail}
              element={<BlogDetail />}
            />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default App;
