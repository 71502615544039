import React  from "react"
import "./services.css"

const FreezerBox =()=> {
    return (
        <div>
            <br />
            <div className="page-service-des">
            Bhavya Ambulance Services is committed to ensuring the respectful and compassionate transportation of the deceased. Our Freezer Box Ambulance services in Hyderabad are equipped with state-of-the-art refrigeration units to maintain the necessary temperature, preserving the body's integrity. We have a team of trained professionals who handle every aspect of the process with sensitivity and professionalism.
            <br /><br /><h5><b>Why Choose Bhavya Ambulance Services? </b></h5>
            <ul style={{ marginLeft: "40px" }}>
                <li>Experienced Team: Our dedicated team has years of experience in providing Freezer Box Ambulance services, ensuring a seamless and respectful process.</li>
                <li>Timely Response: We understand the urgency of the situation, and our services are available 24/7 to respond promptly.</li>
                <li>Modern Equipment: Our ambulances are equipped with the latest refrigeration technology to maintain the required temperature.</li>
                <li>Compassion and Dignity: We treat every situation with utmost compassion and ensure that your loved one is transported with the dignity they deserve</li>
            </ul>
            <br />When you need Freezer Box Ambulance services in Hyderabad, Bhavya Ambulance Services is here for you. Contact us at Bhavya Ambulance Services for prompt and caring assistance during this difficult time. We are dedicated to providing you with peace of mind when it matters most.
            </div>
            </div>
    )
}

export default FreezerBox;