import React from "react";
import BlogCard from './blog-card'
import "./blogs.css"

const Blogs = () => {
    return (
        <div className="blog-section">
            <p className="blog-section-title">Latest Blog</p>
            <div className="blog-card-section">
                <BlogCard
                    imageUrl="images/blog-1.png"
                    title='The Best Ambulance services in Hyderabad – Bhavya Ambulance Services'
                    time="5"
                    date="October 5, 2023"
                />
                <BlogCard
                    imageUrl="images/blog-2.png"
                    title='Hyderabad Ambulance Services - A Lifeline in Critical Moments | Bhavya Ambulance'
                    name='Suresh Subramanian'
                    time="5"
                    date="November 27, 2023"
                />
                <BlogCard
                    imageUrl="images/blog-3.png"
                    title='Bhavya Ambulance Services Pioneering Corporate Health and Wellness in Hyderabad'
                    name='Keerthi Shetty'
                    time="5"
                    date="July 7, 2023"
                />
                <BlogCard
                    imageUrl="images/blog-4.png"
                    title='Revolutionizing Emergency Healthcare in India'
                    name='Gokila'
                    time="5"
                    date="July 16, 2023"
                />
                <BlogCard
                    imageUrl="images/blog-5.png"
                    title='Hyderabad Ambulance Services - A Lifeline in Critical Moments | Bhavya Ambulance'
                    name='Chandra'
                    time="5"
                    date="December 3, 2023"
                />
                <BlogCard
                    imageUrl="images/blog-6.png"
                    title='Bhavya Ambulance Services Pioneering Corporate Health and Wellness in Hyderabad'
                    name='Suresh Thondappu'
                    time="5"
                    date="August 10, 2023"
                />
            </div>
        </div>
    )
}

export default Blogs;