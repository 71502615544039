import React, {useEffect} from "react"
import {Helmet} from "react-helmet";
import "../../common/css/global.css"
import Blogs from '../../components/blogs'
import "./blogs.css"

const BlogsPage = ()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
        <Helmet>
            <title>Blogs | Ambulance services near me</title>
            <meta name="description" content="Blogs Ambulance Services - Swift, Reliable, and Professional. Call Bhavya Ambulance services- 9318108108 for Immediate Assistance in Hyderabad" />
            <meta name="keywords" content="Blogs ambulance services" />
        </Helmet>
        <div className="page-blogs-are">
            <div className="page-banner page-banner-blogs">
                <img src="images/blog.png" alt="banner"/>
            </div>
            <div className="whoweare-section component-layout">
                
                <Blogs />
            </div>
            
            
        </div>
        </>
    )
}

export default BlogsPage;